.is-visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.not-visible {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(-100%);
    transition: all 1s ease-in-out; 
}

.fade-in {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(-100%);
    transition: all 1s ease-in-out; 

}

.fade-in.is-visible {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}